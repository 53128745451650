import { handleDiceData } from "../../helpers/localHelperFunctions";
import {
    SET_CONFIGURATION_DATA,
    SET_CONFIRM_PLAYED_DATA,
    SET_CONNECTION_DATA,
    SET_CUBE_ACCEPTED_DATA,
    SET_CUBE_PLAYED_DATA,
    SET_DOUBLE_STAKE_ACCEPT_DATA,
    SET_DOUBLE_STAKE_PLAYED_DATA,
    SET_END_GAME_DATA,
    SET_GAME_DATA,
    SET_LEAVE_MATCH_REQUEST_DATA,
    SET_MOVE_PLAYED_DATA,
    SET_NEW_ROUND_DATA,
    SET_ROLL_PLAYED_DATA,
    SET_ROLLED_NOW,
    SET_SELECTED_STONE_POSSIBLE_MOVES,
    SET_STONE_FROM_COLUMN,
    SET_STONE_TO_COLUMN,
    SET_UNDO_DATA,
    TOGGLE_STONE_ANIMATION
} from "../actions/actionTypes";
import { playChipSound } from "../../helpers/soundHelper";
import { isHistory } from "../../constants";

const initialState = {
    gameDataIsSet: false,
    dice: {
        dice1: null,
        dice2: null,
        isDouble: false,
        bigDice: {},
        smallDice: {},
        bothDices: {}
    },
    stoneToColumn: null,
    stoneFromColumn: null,
    startStoneAnimation: false,
    selectedStonePossibleMoves: null,
    roundNumber: 1,
    rolledNow: false,
    disableAutoRollAndAutoSubmit: false
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_GAME_DATA:
            return setGameData(state, payload);
        case SET_MOVE_PLAYED_DATA:
            return setMovePlayedData(state, payload);
        case TOGGLE_STONE_ANIMATION:
            return toggleStoneAnimation(state, payload);
        case SET_SELECTED_STONE_POSSIBLE_MOVES:
            return setSelectedStonePossibleMoves(state, payload);
        case SET_STONE_FROM_COLUMN:
            return setStoneFromColumn(state, payload);
        case SET_STONE_TO_COLUMN:
            return setStoneToColumn(state, payload);
        case SET_CUBE_PLAYED_DATA:
            return setCubePlayedData(state, payload);
        case SET_CUBE_ACCEPTED_DATA:
            return setCubeAcceptedData(state, payload);
        case SET_DOUBLE_STAKE_PLAYED_DATA:
            return setDoubleStakePlayedData(state, payload);
        case SET_DOUBLE_STAKE_ACCEPT_DATA:
            return setDoubleStakeAcceptData(state, payload);
        case SET_CONFIRM_PLAYED_DATA:
            return setConfirmPlayedData(state, payload);
        case SET_ROLL_PLAYED_DATA:
            return setRollPlayedData(state, payload);
        case SET_NEW_ROUND_DATA:
            return setNewRoundData(state, payload);
        case SET_END_GAME_DATA:
            return setEndGameData(state, payload);
        case SET_CONFIGURATION_DATA:
            return setConfigurationData(state, payload);
        case SET_CONNECTION_DATA:
            return setConnectionData(state, payload);
        case SET_LEAVE_MATCH_REQUEST_DATA:
            return setLeaveMatchRequestData(state, payload, action.bool);
        case SET_UNDO_DATA:
            return setUndoData(state, payload);
        case SET_ROLLED_NOW:
            return setRolledNow(state, payload);

        default:
            return state;
    }
};

const setRolledNow = (state, bool) => {
    return { ...state, rolledNow: bool };
};

const setUndoData = (state, payload) => {
    const { player, opponent, dice, oldDice, dices } = payload;
    payload.dice = handleDiceData(
        dice,
        player,
        opponent.firstDice,
        !state.playerConfiguration?.showPossibleMove,
        oldDice,
        dices
    );
    return {
        ...state,
        ...payload,
        stoneToColumn: null,
        stoneFromColumn: null,
        startStoneAnimation: false,
        selectedStonePossibleMoves: null,
        enableSubmitButton: false
    };
};

const setLeaveMatchRequestData = (state, data, bool) => {
    let canLeaveRound = state.canLeaveRound;
    let disableAutoRollAndAutoSubmit = !bool;
    if (data.isPlayer && bool) {
        canLeaveRound = data.canLeaveRound;
    }

    return {
        ...state,
        isPlayer: data.isPlayer,
        player: {
            ...state.player,
            stepTime: data.player.stepTime,
            gameTime: data.player.gameTime
        },
        opponent: {
            ...state.opponent,
            stepTime: data.opponent.stepTime,
            gameTime: data.opponent.gameTime
        },
        leaveRound: { roundFinishType: data.roundFinishType, declined: bool },
        canLeaveRound,
        disableAutoRollAndAutoSubmit
    };
};
const setConnectionData = (state, data) => {
    const key = data.isPlayer ? "player" : "opponent";
    return {
        ...state,
        [key]: {
            ...state[key],
            isDisconnected: data.isDisconnected
        }
    };
};

const setConfigurationData = (state, data) => {
    const cubeInfo = state.player.cubeInfo;
    let rolledNow = state.rolledNow;
    if (data.autoRoll && !state.playerConfiguration.autoRoll && cubeInfo) {
        cubeInfo.canCube = false;
    }
    if (data.showAnimations && !state.playerConfiguration.showAnimations) {
        rolledNow = false;
    }
    return {
        ...state,
        playerConfiguration: data,
        player: {
            ...state.player,
            cubeInfo
        },
        rolledNow
    };
};

const setGameData = (state, payload) => {
    const { player, opponent, dice, playerConfiguration, oldDice, dices } = payload;
    payload.dice = handleDiceData(
        dice,
        player,
        opponent.firstDice,
        !playerConfiguration?.showPossibleMoves,
        oldDice,
        dices
    );

    return {
        ...state,
        ...payload,
        gameDataIsSet: true,
        roundNumber: ++state.roundNumber
    };
};

const setMovePlayedData = (state, payload) => {
    let { player, opponent, dice, oldDice, dices, fullMove, isPlayer, enableSubmitButton } = payload;
    payload.dice = handleDiceData(
        dice,
        player,
        opponent.firstDice,
        !state.playerConfiguration?.showPossibleMove,
        oldDice,
        dices
    );

    if ((fullMove && !state.playerConfiguration?.showAnimations) || fullMove?.from === 101 || fullMove?.to === 100)
        playChipSound();
    let rolledNow = state.rolledNow;
    if (isPlayer !== state.isPlayer && state.playerConfiguration?.showAnimations) rolledNow = true;
    return {
        ...state,
        ...payload,
        stoneToColumn: null,
        stoneFromColumn: null,
        startStoneAnimation: false,
        selectedStonePossibleMoves: null,
        canLeaveRound: !(isPlayer && !dices?.length && !enableSubmitButton),
        rolledNow
    };
};

const toggleStoneAnimation = (state, payload) => {
    return {
        ...state,
        startStoneAnimation: payload
    };
};

const setSelectedStonePossibleMoves = (state, payload) => {
    return {
        ...state,
        selectedStonePossibleMoves: payload
    };
};

const setStoneFromColumn = (state, payload) => {
    // playChipSound();
    return {
        ...state,
        stoneFromColumn: payload
    };
};

const setStoneToColumn = (state, payload) => {
    return {
        ...state,
        stoneToColumn: payload
    };
};

const setRollPlayedData = (state, data) => {
    let { dice, opponent, player, dices, isPlayer, boardInfo, enableSubmitButton } = data;
    const showPossibleMoves = state.playerConfiguration?.showPossibleMoves;
    dice = handleDiceData(dice, player, opponent.firstDice, !showPossibleMoves, null, dices);
    let rolledNow = state.rolledNow;
    if (state.playerConfiguration?.showAnimations) rolledNow = true;
    return {
        ...state,
        dice,
        player,
        isPlayer: isHistory ? isPlayer : state.isPlayer,
        opponent,
        changedDice: null,
        rolledNow,
        enableRollButton: false,
        canLeaveRound: !(isPlayer && !dices?.length && !enableSubmitButton),
        boardInfo: isHistory ? boardInfo : state.boardInfo
    };
};

const setConfirmPlayedData = (state, data) => {
    let { dice, enableRollButton, isPlayer, opponent, player, dices } = data;
    const showPossibleMoves = state.playerConfiguration?.showPossibleMoves;
    dice = handleDiceData(dice, player, opponent.firstDice, !showPossibleMoves, null, dices);

    let rolledNow = state.rolledNow;
    let enableSubmitButton;
    enableSubmitButton = !!(
        isPlayer &&
        state.playerConfiguration?.autoRoll &&
        !state.playerConfiguration?.autoSubmit &&
        !dices
    );

    if (isPlayer !== state.isPlayer && state.playerConfiguration?.showAnimations) rolledNow = true;
    return {
        ...state,
        dice,
        isPlayer,
        enableRollButton,
        player,
        opponent,
        oldDice: null,
        enableSubmitButton,
        rolledNow
    };
};

const setCubePlayedData = (state, data) => {
    return {
        ...state,
        isPlayer: data.isPlayer,
        player: {
            ...state.player,
            stepTime: data.player.stepTime,
            gameTime: data.player.gameTime,
            cubeInfo: {
                ...state.player.cubeInfo,
                canCube: false,
                cubeRequestNumber: data.cubeNumber,
                withRedouble: data.withRedouble,
                isPlaying: true,
                isPlayerCube: data.isPlayer
            },
            notification: data.player.notification
        },
        opponent: {
            ...state.opponent,
            stepTime: data.opponent.stepTime,
            gameTime: data.opponent.gameTime,
            notification: data.opponent.notification
        }
    };
};

const setCubeAcceptedData = (state, data) => {
    return {
        ...state,
        isPlayer: data.isPlayer,
        player: {
            ...state.player,
            stepTime: data.player.stepTime,
            gameTime: data.player.gameTime,
            cubeInfo: {
                ...state.player.cubeInfo,
                canCube: false,
                cubeCurrentNumber: data.cubeNumber,
                cubeRequestNumber: 0,
                withRedouble: false,
                isPlaying: false,
                isPlayerCube: data.isPlayerCube
            },
            notification: data.player.notification
        },
        opponent: {
            ...state.opponent,
            stepTime: data.opponent.stepTime,
            gameTime: data.opponent.gameTime,
            notification: data.opponent.notification
        }
    };
};

const setDoubleStakePlayedData = (state, data) => {
    return {
        ...state,
        isPlayer: data.isPlayer,
        player: {
            ...state.player,
            stepTime: data.player.stepTime,
            gameTime: data.player.gameTime,
            notification: data.player.notification,
            doubleStakeInfo: {
                ...state.player.doubleStakeInfo,
                canDoubleStake: false,
                requestedStake: data.requestedStake,
                withRedouble: data.canRedouble,
                isPlaying: true,
                isOnPlayer: data.isPlayer
            }
        },
        opponent: {
            ...state.opponent,
            stepTime: data.opponent.stepTime,
            gameTime: data.opponent.gameTime,
            notification: data.opponent.notification
        }
    };
};

const setDoubleStakeAcceptData = (state, data) => {
    return {
        ...state,
        isPlayer: data.isPlayer,
        player: {
            ...state.player,
            stepTime: data.player.stepTime,
            gameTime: data.player.gameTime,
            notification: data.player.notification,
            doubleStakeInfo: {
                ...state.player.doubleStakeInfo,
                canDoubleStake: false,
                currentStake: data.currentStake,
                withRedouble: false,
                isPlaying: false
            }
        },
        opponent: {
            ...state.opponent,
            stepTime: data.opponent.stepTime,
            gameTime: data.opponent.gameTime,
            notification: data.opponent.notification
        }
    };
};

const setNewRoundData = (state, payload) => {
    const { player, opponent, dice, oldDice, dices } = payload;
    payload.dice = handleDiceData(
        dice,
        player,
        opponent.firstDice,
        !state.playerConfiguration?.showPossibleMove,
        oldDice,
        dices
    );

    return {
        ...state,
        ...payload,
        leaveRound: null,
        canLeaveRound: true,
        oldDice: null,
        enableRollButton: false,
        enableSubmitButton: false,
        stoneToColumn: null,
        stoneFromColumn: null,
        selectedStonePossibleMoves: null,
        roundNumber: ++state.roundNumber,
        startStoneAnimation: false,
        disableAutoRollAndAutoSubmit: false
    };
};

const setEndGameData = (state, payload) => {
    return {
        ...state,
        ...payload,
        player: {
            ...state.player,
            gameTime: 0,
            stepTime: 0
        },
        opponent: {
            ...state.opponent,
            gameTime: 0,
            stepTime: 0
        }
    };
};
